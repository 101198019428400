import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (localStorage.getItem('simplyNotifiedToken')) {
      let token = localStorage.getItem('simplyNotifiedToken');
      const req = request.clone({
        headers: new HttpHeaders({
          'token': token,
        })
      });
      return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }),
        catchError((err: any) => {
          this.spinner.hide();
          if (err.error.status === 0) {
            this.toastr.error('You are offline.!');
          }
          else {
            if (err.error.status === 401) {
              this.toastr.error(err.error.message);
              localStorage.clear();
              this.router.navigate(['/sign-in']);
            }
            else if(err.error.message === "No subscription event found."){
              console.log("inside error")
              this.toastr.error(err.error.message);
              this.router.navigate(['/home/plan']);
            }
            else {
              this.toastr.error(err.error.message);
            }
          }
          return of(err);
        }));
    }
    else {
      const req = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });

      return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }),
        catchError((err: any) => {
          this.spinner.hide();
          if (err.error.status === 0) {
            this.toastr.error('You are offline.!');
            this.router.navigate(['/sign-in']);
          }
          else {
            if (err.error.status == 401) {
              this.toastr.error(err.error.message);
              localStorage.removeItem('simplyNotifiedToken');
              this.router.navigate(['/sign-in']);
            }
            else if (err.error.status == 400) {
              if (err.error.message === 'Account not verified') {
                this.toastr.error(err.error.message);
                this.router.navigate(['/email-otp']);
              }
             
              this.toastr.error(err.error.message);
            }
            else {
              this.toastr.error(err.error.message);
            }
            
          }
          return of(err);
        }));
    }
  }
}
