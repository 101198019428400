import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate() {
    if (!localStorage.getItem('simplyNotifiedToken')) {
      this.router.navigate(['/sign-in']);
      return false;
    }
    else {
      return true;
    }
  }

}
