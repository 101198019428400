import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginGuardGuard} from '../app/services/login-guard/login-guard.guard';
import {GuardDashboardGuard} from '../app/services/guard/guard-dashboard.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('../app/content/auth/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('../app/content/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'admin-set-password/:token',
    loadChildren: () => import('../app/content/auth/set-admin-password/set-admin-password.module').then(m => m.SetAdminPasswordModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('../app/content/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('../app/content/auth/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'email-otp',
    loadChildren: () => import('../app/content/auth/email-otp/email-otp.module').then(m => m.EmailOtpModule),
    canActivate:[GuardDashboardGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('../app/content/pages/pages.module').then(m => m.PagesModule),
    canActivate: [LoginGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
