import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GuardDashboardGuard implements CanActivate {
  constructor(private router: Router)
  {
  }
  canActivate(){
    if (localStorage.getItem('simplyNotifiedToken')) {
      this.router.navigate(['/home']);
      return false;
    }
    else {
      return true;

    }
  }
  
}
