import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  typeSelected: string;
  constructor(private spinner: NgxSpinnerService)
  {
    this.typeSelected = 'ball-scale-pulse';
  }
  ngOnInit(): void {
  }
}
